
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonStandard {
  position: relative;
  padding: spacing(1.75) spacing(5);
  background-color: $color-white-primary;
  color: $color-black;
  text-decoration: none;
  text-align: center;
  transition: color 250ms ease-in-out;
  transform: perspective(1px) translateZ(0);
  @include font-smoothing();

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background: rgba($color-black, 0.1);
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:active,
  &:hover,
  &:focus {
    &::before {
      transform: scaleX(1);
    }
  }
}

.ButtonStandardContent {
  display: flex;
  align-items: center;
  justify-content: center;

  &.hasIcon {
    margin-top: 2px;
    justify-content: space-between;
    flex-wrap: nowrap;

    &.reversed {
      flex-direction: row-reverse;
    }
  }
}

.ButtonStandardSpinner {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.primary {
  background-color: var(--color-theme--secondary);
  color: $color-white-primary;

  //&:hover,
  //&:focus,
  //&:active {
  //  background-color: darken($color-grey-secondary, 10);
  //}

  &.outline {
    background-color: $color-white-primary;
    color: var(--color-theme--secondary);
    box-shadow: inset 0 0 0 1px var(--color-theme--secondary);

    &::before {
      background-color: var(--color-theme--secondary);
    }
  }
}

.outline {
  background: transparent;
  box-shadow: inset 0 0 0 1px $color-misc-divider;

  &.primary {
    box-shadow: inset 0 0 0 1px var(--color-theme--secondary);
    background: transparent;
  }

  &.inverse {
    color: $color-white-primary;
  }

  &::before {
    background-color: var(--color-theme--secondary);
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-white-primary;
    box-shadow: inset 0 0 0 1px var(--color-theme--secondary);
  }
}

.small {
  padding: spacing(1.25) spacing(2);
}

.large {
  padding: spacing(2) spacing(5);
}

.bloated {
  padding: spacing(2.5) spacing(5);
}

.huge {
  padding: spacing(2.1) spacing(8.375);
}

.disabled {
  background-color: $color-misc-divider;
  border-color: $color-misc-divider;
}

.loading {
  pointer-events: none;

  .ButtonStandardContent {
    transition-duration: $duration-shortest;
    opacity: 0;
  }

  .ButtonStandardSpinner {
    opacity: 1;
  }
}

.Text {
  margin-right: spacing(2);
  @include mq($breakpoint-desktop) {
    margin-right: spacing(0);
  }
}

.Icon {
  @include mq($breakpoint-desktop) {
    margin-left: spacing(2);
    margin-bottom: 3px;

    .reversed & {
      margin-left: 0;
      margin-right: spacing(2);
    }
  }
}
