
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  width: 100%;
  clear: both;
}

.noClearfix {
  clear: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  @include mq($breakpoint-desktop) {
    display: flex;
    justify-content: space-between;
  }
}

.gutter {
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include mq($breakpoint-desktop) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

// SIZES

.wide {
  max-width: spacing(174);

  &.gutter {
    max-width: spacing(174 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(174 + 4);
    }
  }
}

.large {
  max-width: spacing(149);

  &.gutter {
    max-width: spacing(149 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(149 + 4);
    }
  }
}

.mediumLarge {
  max-width: spacing(136);

  &.gutter {
    max-width: spacing(136 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(136 + 4);
    }
  }
}

.mediumSmall {
  max-width: spacing(93);
  &.gutter {
    max-width: spacing(93 + 2);

    @include mq($breakpoint-desktop) {
      max-width: spacing(93 + 4);
    }
  }
}

.medium {
  max-width: spacing(90);
}

.small {
  max-width: spacing(61);
}

.auto {
  max-width: none;
  width: auto;
}
