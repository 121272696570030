
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$spinner-duration: 800ms;
$spinner-step-duration: $spinner-duration / 8;

.Spinner {
  display: inline-block;
  width: 21px;
  height: 21px;
  line-height: 21px;
  opacity: 0;
  animation: spinner-reveal 1000ms $easing-standard 1 forwards;
}

.SpinnerInner {
  transform: translateZ();

  circle {
    opacity: 0;
    animation: spinner-dot-fade $spinner-duration linear infinite;

    .paused & {
      animation-play-state: paused;
    }
  }

  @for $i from 1 through 8 {
    circle:nth-child(#{$i}) {
      animation-delay: -($i - 1) * $spinner-step-duration;
    }
  }

  svg {
    vertical-align: bottom;
    fill: currentColor;
  }
}

@keyframes spinner-dot-fade {
  10% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes spinner-reveal {
  100% {
    opacity: 1;
  }
}
