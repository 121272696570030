
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$gap-list: (
  'tiny': (
    'mobile': 0.5,
    'tablet': 0.5,
    'desktop': 1,
    'desktopMedium': 1
  ),
  'small': (
    'mobile': 1,
    'tablet': 1,
    'desktop': 2,
    'desktopMedium': 2
  ),
  'medium': (
    'mobile': 3,
    'tablet': 3,
    'desktop': 4,
    'desktopMedium': 4
  ),
  'large': (
    'mobile': 2,
    'tablet': 4,
    'desktop': 6,
    'desktopMedium': 8
  )
);

// .Stack {}

@each $key, $value in $gap-list {
  .#{$key} > * + * {
    margin-top: spacing(map-get($value, 'mobile'));

    @include mq($breakpoint-tablet) {
      margin-top: spacing(map-get($value, 'tablet'));
    }

    @include mq($breakpoint-desktop) {
      margin-top: spacing(map-get($value, 'desktop'));
    }

    @include mq($breakpoint-desktopMedium) {
      margin-top: spacing(map-get($value, 'desktopMedium'));
    }
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gaps: map-keys($gap-list);
// }
