
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
$gap-list: (
  'tiny': 0.25,
  'small': 0.5,
  'medium': 1,
  'large': 2.5
);

.Inline {
  display: flex;
  flex-wrap: wrap;
}

.shrink {
  display: inline-flex;
}

.center {
  justify-content: center;
}

.middle {
  align-items: center;
}

.noWrap {
  flex-wrap: nowrap;
}

@each $key, $value in $gap-list {
  .#{$key} {
    margin: spacing($value * -1);
    & > * {
      margin: spacing($value);
    }
  }
}

.large-responsive {
  margin: spacing(-0.5);
  & > * {
    margin: spacing(0.5);
  }
  @include mq($breakpoint-tablet) {
    margin: spacing(-1);
    & > * {
      margin: spacing(1);
    }
  }
  @include mq($breakpoint-desktop) {
    margin: spacing(-2);
    & > * {
      margin: spacing(2);
    }
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gaps: map-keys($gap-list);
// }
