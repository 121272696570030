
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// WARNING: Adding styles here could override bespoke styling in other button
// components. Only add styles that are required across ALL button components.

.ButtonBase {
  display: inline-block;
  vertical-align: middle;

  &:focus,
  &:hover {
    outline: 0;
  }

  // &:active {}
}

.disabled {
  user-select: none;
  pointer-events: none;
  cursor: not-allowed;
}

.block {
  display: block;
  width: 100%;
  min-width: 0 !important;
}
