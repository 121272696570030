
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../lib/GridBase';
@import '../lib/GridWidths';

$columns: (
  m: (
    2,
    3,
    11
  ),
  t: (
    2,
    3,
    4
  ),
  d: (
    3,
    4,
    5,
    6,
    8,
    9,
    11
  ),
  dm: (
    2,
    4
  )
);

.GridItem {
  @include grid-base;
}

@include grid-widths('m', $columns);

@include mq($breakpoint-tablet) {
  @include grid-widths('t', $columns);
}

@include mq($breakpoint-desktop) {
  @include grid-widths('d', $columns);
}

@include mq($breakpoint-desktopMedium) {
  @include grid-widths('dm', $columns);
}

.trailingGap-default {
  margin-bottom: spacing(3);
}

.trailingGap-desktop {
  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(3);
  }
}

.trailingGap-mobile {
  @include mq($max: $breakpoint-desktop - 1) {
    margin-bottom: spacing(3);
  }
}

.hidden-mobile {
  @include mq($max: $breakpoint-desktop - 1) {
    display: none;
  }
}

.hidden-desktop {
  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   columns: map-keys($columns);
// }
