
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .Type {}

$font-heading-zero: 52px;
$font-heading-one: 40px;
$font-heading-two: 32px;
$font-heading-three: 28px;
$font-heading-four: 26px;
$font-heading-five: 24px;
$font-heading-six: 20px;
$font-heading-seven: 18px;
$font-heading-eight: 16px;

$font-body-24: 24px;
$font-body-20: 20px;
$font-body-16: 16px;
$font-body-14: 14px;
$font-body-12: 12px;
$font-body-10: 10px;

$font-display-zero: 72px;
$font-display-one: 60px;
$font-display-two: 44px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

$font-height-120: 120%;
$font-height-130: 130%;
$font-height-140: 140%;
$font-height-160: 160%;
$font-height-220: 220%;

$font-tracking-2: 0.2em;
$font-tracking-3: 0.3em;
$font-tracking-4: 0.4em;
$font-tracking-5: 0.52em;

.base {
  font-size: $font-body-20;
  line-height: $font-height-160;
  font-weight: $font-weight-medium;
}

.display0 {
  font-size: $font-display-zero;
  line-height: $font-height-120;
}

@include mq($max: $breakpoint-desktop - 1) {
  .m-display0 {
    font-size: $font-display-zero;
    line-height: $font-height-120;
  }
}
@include mq($breakpoint-desktop) {
  .d-display0 {
    font-size: $font-display-zero;
    line-height: $font-height-120;
  }
}

.heading0 {
  font-size: $font-heading-zero;
  line-height: $font-height-120;
}

@include mq($max: $breakpoint-desktop - 1) {
  .m-heading0 {
    font-size: $font-heading-zero;
    line-height: $font-height-120;
  }
}
@include mq($breakpoint-desktop) {
  .d-heading0 {
    font-size: $font-heading-zero;
    line-height: $font-height-120;
  }
}

$display-sizes: (
    'size': $font-display-one,
    'height': $font-height-120
  ),
  (
    'size': $font-display-two,
    'height': $font-height-120
  );

$heading-sizes: (
    'size': $font-heading-one,
    'height': $font-height-130
  ),
  (
    'size': $font-heading-two,
    'height': $font-height-130
  ),
  (
    'size': $font-heading-three,
    'height': $font-height-130
  ),
  (
    'size': $font-heading-four,
    'height': $font-height-130
  ),
  (
    'size': $font-heading-five,
    'height': $font-height-130
  ),
  (
    'size': $font-heading-six,
    'height': $font-height-130
  ),
  (
    'size': $font-heading-seven,
    'height': $font-height-120
  ),
  (
    'size': $font-heading-eight,
    'height': $font-height-120
  );

$body-sizes: (
    'size': $font-body-20,
    'height': $font-height-160
  ),
  (
    'size': $font-body-16,
    'height': $font-height-140
  ),
  (
    'size': $font-body-14,
    'height': $font-height-140
  ),
  (
    'size': $font-body-12,
    'height': $font-height-140
  ),
  (
    'size': $font-body-10,
    'height': $font-height-120
  );

$type-styles: (
  'display': $display-sizes,
  heading: $heading-sizes,
  body: $body-sizes
);

@each $key, $value in $type-styles {
  $sizesList: length($value);
  @for $i from 1 through $sizesList {
    $size: map-get(nth($value, $i), 'size');
    $height: map-get(nth($value, $i), 'height');
    .#{$key}#{$i} {
      font-size: $size;
      line-height: $height;
    }

    @include mq($max: $breakpoint-desktop - 1) {
      .m-#{$key}#{$i} {
        font-size: $size;
        line-height: $height;
      }
    }
    @include mq($breakpoint-desktop) {
      .d-#{$key}#{$i} {
        font-size: $size;
        line-height: $height;
      }
    }
  }
}

$weights: (
  'extrabold': $font-weight-extrabold,
  'bold': $font-weight-bold,
  'medium': $font-weight-medium,
  'regular': $font-weight-regular
);

@each $key, $value in $weights {
  .#{$key} {
    font-weight: $value;
  }

  @include mq($max: $breakpoint-desktop - 1) {
    .m-#{$key} {
      font-weight: $value;
    }
  }
  @include mq($breakpoint-desktop) {
    .d-#{$key} {
      font-weight: $value;
    }
  }
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

// TODO: IMPROVE
.march8 {
  font-family: $font-bodoni;
}
