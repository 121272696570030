
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Surround {
  height: 100%;
  &::after {
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

.padded-default {
  padding-bottom: spacing(2);
}

.padded-mobile {
  @include mq($max: $breakpoint-desktop - 1) {
    padding-bottom: spacing(2);
  }
}

.padded-desktop {
  @include mq($breakpoint-desktop) {
    padding-bottom: spacing(2);
  }
}

.bordered-default {
  position: relative;
  &::after {
    content: '';
  }
}

.bordered-mobile {
  position: relative;
  @include mq($max: $breakpoint-desktop - 1) {
    &::after {
      content: '';
    }
  }
}

.bordered-tablet {
  position: relative;
  @include mq($breakpoint-tablet) {
    &::after {
      content: '';
    }
  }
}

.bordered-desktop {
  position: relative;
  @include mq($breakpoint-desktop) {
    &::after {
      content: '';
    }
  }
}

.border-width--thin {
  &::after {
    height: 1px;
  }
}

.border-width--medium {
  &::after {
    height: 1.5px;
  }
}

.border-width--thick {
  &::after {
    height: 2px;
  }
}

.border-color--darkGrey {
  &::after {
    background-color: $color-grey-secondary;
  }
}

.border-color--grey {
  &::after {
    background-color: $color-misc-divider;
  }
}

.border-color--lightGrey {
  &::after {
    background-color: $color-white-secondary;
    opacity: 0.2;
  }
}
