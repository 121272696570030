
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .TitleWrapper {
//   &.inverse {
//     background-color: $color-black-primary;
//   }
// }

.TitleWrapper {
  @include mq($max: $breakpoint-desktop - 1) {
    margin: 0 spacing(-2);
    padding: 0 spacing(2);
  }
}

.Title {
  color: $color-black-primary;
  line-height: 170%;

  @include mq($breakpoint-desktop) {
    line-height: 154%;
  }

  .inverse & {
    color: $color-white-primary;
  }
}

.TitleInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: spacing(2) 0;

  .inverse & {
    padding: spacing(2) 0 0;
  }
}
