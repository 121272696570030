
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CardWrapper {
  position: relative;
  height: 100%;
}

.CardLink {
  text-decoration: none;
  color: currentColor;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    inset: 0;
  }
}

.Card {
  position: relative;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: inherit;

  &:focus-within a:focus {
    text-decoration: none;
    outline: none;
  }

  &:has(.CardLink:active, .CardLink:hover) {
    .CardTitle {
      color: var(--color-theme--secondary) !important;
    }

    .CardButton {
      border-color: var(--color-theme--secondary);
      color: $color-white-primary;
    }
  }
}

.CardImage {
  margin-bottom: spacing(2);
  width: 100%;
  position: relative;
  z-index: 1;

  > div {
    overflow: hidden;
    background-color: var(--color-theme--primary);

    img {
      transition: 250ms linear;
      box-sizing: border-box;

      .Card:has(a:active) &,
      .Card:has(a:hover) &,
      .Card:has(a:focus) & {
        transform: scale(1.1);
        opacity: 0.9;
      }
    }
  }

  .horizontal & {
    margin-right: spacing(3);
    margin-bottom: 0;
  }

  @include mq($breakpoint-desktop) {
    margin-bottom: spacing(1.5);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 1px $color-misc-divider;
    z-index: 1;

    .inverse & {
      display: none;
    }
  }
}

.CardImageIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(20px);
  border-radius: 50%;
  overflow: hidden;

  path {
    fill: rgba($color-white-primary, 0.5);
  }

  @include mq($max: $breakpoint-desktop - 1) {
    width: spacing(4.5) !important;
    height: spacing(4.5) !important;
    line-height: spacing(4.5) !important;
  }
}

.CardHeader {
  margin-bottom: spacing(1.5);
  position: relative;
  z-index: 1;
}

.CardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.border-bottom {
    border-bottom: 1px solid $color-misc-divider;
    padding-bottom: spacing(2);
  }

  &.cappedHeight {
    height: 70%;
  }
}

.CardContentShade {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: -600px;
    left: -2000px;
    right: -2000px;
    bottom: spacing(-3);
    background: $color-black-secondary;

    @include mq($max: $breakpoint-desktop - 1) {
      display: none;
    }
  }
}

.CardInner {
  z-index: 1;

  .border-top & {
    border-top: 2px solid var(--color-theme--secondary);
    padding-top: spacing(2);
  }

  .inverse:not(.horizontal) .border-top & {
    margin-top: spacing(1.5);
  }

  * + * {
    margin-top: spacing(0.5);
    z-index: 1;

    @include mq($breakpoint-desktop) {
      margin-top: spacing(1);
    }
  }
}

.CardDescription {
  color: $color-grey-primary;

  .inverse & {
    color: $color-white-primary;
  }
}

.CardFooter {
  margin-top: spacing(2);
  display: inherit;

  &.onTop {
    position: relative;
    z-index: 2;
  }

  @include mq($breakpoint-desktop) {
    align-self: flex-start;
  }
}

.CardButton {
  border: 1px solid $color-misc-divider;
  font-weight: 500;
  font-size: $font-body-12;
  line-height: 1;
  position: relative;
  padding: spacing(1.75) spacing(5);
  background-color: $color-white-primary;
  color: $color-black;
  text-decoration: none;
  text-align: center;
  transform: perspective(1px) translateZ(0);
  transition: color 250ms ease-in-out;
  @include font-smoothing();
  @include mq($max: $breakpoint-desktop - 1) {
    width: 100%;
    font-size: $font-body-16;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    background: var(--color-theme--secondary);
  }

  .Card:has(a:active) &,
  .Card:has(a:hover) &,
  .Card:has(a:focus) & {
    &::before {
      transform: scaleX(1);
    }
  }
}

.GridWrapper {
  width: 100%;
}

.horizontal {
  flex-direction: row;
  height: 100%;
}

.inverse {
  background: $color-black-primary;
  color: $color-white-primary;
}

.inset {
  padding: spacing(2);
  box-shadow: 0 4px 28px rgba(0, 0, 0, 0.5);

  &:hover {
    box-shadow: 0 4px 40px $color-misc-black;
  }
}
